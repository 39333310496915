import React from "react";
import { Field, Form, Formik } from "formik";
import { Col, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import DropZone from "components/dropzone";
import { successToast, errorToast } from "utils";
import { ErrorMessage } from "components/common/error-msg";
import { useNavigate } from "react-router-dom";
import Card from "components/card";
import { postForm } from "services/api_Helpers";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddBlogs = (props) => {
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      // Convert form values into FormData
      const formData = new FormData();

      // Append regular fields
      formData.append('title', values.title);
      formData.append('content', values.content);
      formData.append('status', values.status);

      // Append images
      if (values.images) {
        for (let i = 0; i < values.images.length; i++) {
          formData.append('images', values.images[i]);
        }
      }

      const res = await postForm('/add-blog', formData);
      successToast(res.data.message); // Assuming `res.data.message` contains success message
      resetForm();
      navigate('/admin/all-blogs');
    } catch (error) {
      errorToast(error.response?.data?.message || error.message);
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    images: Yup.mixed().required("Images are required"),
    content: Yup.string().required("Content is required"),
    status: Yup.string().required("Status is required"),
  });

  const statusOptions = [
    { label: "Select status", value: "" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];

  return (
    <Card extra="flex w-full h-full mt-4 flex-col">
      <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Add New Blog
        </h4>
      </div>
      <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-1 2xl:grid-cols-1">
        <Formik
          initialValues={{
            title: "",
            images: [],
            content: "",
            status: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
                <div className="px-3 py-3">
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Field
                          label="Title"
                          placeholder="Enter your Title"
                          type="text"
                          name="title"
                          component={InputField}
                        />
                        <ErrorMessage error={errors.title} />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <label
                          className="text-sm text-navy-700 dark:text-white ml-3 font-bold mb-2"
                          htmlFor="status"
                        >
                          Status
                        </label>
                        <Field
                          name="status"
                          selectorOptions={statusOptions}
                          selectorLabelKey="label"
                          selectorValueKey="value"
                          placeholder="Select status"
                          component={SelectField}
                        />
                        {errors.status && touched.status ? (
                          <ErrorMessage error={errors.status} />
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup>
                        <label className="text-sm text-navy-700 dark:text-white ml-3 font-bold mb-2">
                          Content
                        </label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={values.content}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setFieldValue('content', data);
                          }}
                        />
                        {errors.content && touched.content ? (
                          <ErrorMessage error={errors.content} />
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup>
                        <DropZone
                          setFieldValue={setFieldValue}
                          errors={errors?.images}
                          values={values}
                          label="Add Images"
                          name="images"
                          multiple={true}
                          accept={{
                            "image/*": [".png", ".jpg", ".jpeg", ".svg", ".webp"],
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-end">
                    <button
                      type="submit"
                      className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                      disabled={isSubmitting}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Card>
  );
};

export default AddBlogs;
