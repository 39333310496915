import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import { Button } from "components/ui-elements/Button";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import moment from "moment";
import { put } from "services/api_Helpers";

const statusOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

const EditModal = ({ blog, onClose, onUpdate }) => {
  const [initialValues, setInitialValues] = useState({
    title: "",
    status: "",
    content: "",
    createdAt: new Date(),
  });

  useEffect(() => {
    if (blog) {
      setInitialValues({
        title: blog.title,
        status: blog.status,
        content: blog.content,
        createdAt: new Date(blog.createdAt),
      });
    }
  }, [blog]);

  const handleUpdate = async (values) => {
    try {
      await put(`/blogs/${blog._id}`, values);
      onUpdate();
      onClose();
    } catch (error) {
      console.error("Error updating blog:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-100">
      <div className="bg-white p-8 rounded shadow-lg w-full max-w-4xl">
        <h3 className="text-2xl font-bold mb-4">Edit Blog</h3>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleUpdate}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="grid grid-cols-1 gap-4">
                <Field
                  label="Title"
                  name="title"
                  type="text"
                  placeholder="Enter blog title"
                  component={InputField}
                />
                <Field
                  name="status"
                  selectorOptions={statusOptions}
                  selectorLabelKey="label"
                  selectorValueKey="value"
                  placeholder="Select status"
                  component={SelectField}
                />
                <div>
                  <label className="block text-sm font-bold text-gray-900 dark:text-white">Created Date</label>
                  <input
                    type="date"
                    name="createdAt"
                    className="w-full border-gray-200 rounded"
                    onChange={(e) => setFieldValue("createdAt", e.target.value)}
                    value={values.createdAt ? moment(values.createdAt).format("YYYY-MM-DD") : ""}
                  />
                </div>
                <div>
                  <label className="block text-sm font-bold text-gray-900 dark:text-white">Content</label>
                  <div className="border rounded">
                    <CKEditor
                      editor={ClassicEditor}
                      data={values.content}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue("content", data);
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-end gap-2 mt-4">
                  <button type="submit" className="bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-800">
                    Save
                  </button>
                  <button type="button" onClick={onClose} className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700">
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditModal;
