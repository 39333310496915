import React, { useState, useEffect } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { Edit, Trash } from "react-feather";
import { Calendar } from "react-calendar";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Link } from "react-router-dom";
import Card from "components/card";
import DataTable from "components/dataTable/reactDataTable";
import InputField from "components/fields/InputField";
import DeleteBlog from "./deleteBlog";
import { Button } from "components/ui-elements/Button";
import SelectField from "components/fields/SelectField";
import axios from 'axios';

import { get } from "services/api_Helpers";
import EditModal from "./BlogEditModal";
import { deleted } from "services/api_Helpers";

const statusOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

const AllBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async (filters = {}) => {
    setLoading(true);
    try {
      const response = await get('/blogs', { params: filters });
      setBlogs(response.data.data);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (values) => {
    const filters = {
      title: values.title,
      status: values.status,
      createdDate: values.date ? moment(values.date).format("YYYY-MM-DD") : undefined,
    };
    fetchBlogs(filters);
  };

  const handleEditBlog = (blog) => {
    setSelectedBlog(blog);
    setEditModalVisible(true);
  };

  const handleUpdateBlog = () => {
    fetchBlogs(); // Refresh the blog list after update
    setEditModalVisible(false);
    setSelectedBlog(null);
  };

  const handleDeleteBlog = async () => {
    try {
      await deleted(`/blogs/${selectedBlog._id}`);
      setBlogs(blogs.filter(b => b._id !== selectedBlog._id));
      console.log("Blog deleted:", selectedBlog);
    } catch (error) {
      console.error("Error deleting blog:", error);
    } finally {
      setDeleteModalVisible(false);
      setSelectedBlog(null);
    }
  };

  const toggleCalendar = () => {
    setCalendarVisible(!isCalendarVisible);
  };

  const columns = [
    {
      name: "Blog Name",
      maxWidth: "350px",
      selector: (row) => (
        <div className="flex items-center gap-2 py-2">
          <img
            className="h-14 w-14 rounded"
            src={row?.images ? row?.images[0] : "/assets/images/notfound.svg"}
            onError={(e) => {
              e.target.src = "/assets/images/notfound.svg";
            }}
            alt="blog"
          />
          <div>
            <p className="font-semibold">{row?.title}</p>
          </div>
        </div>
      ),
    },
    {
      name: "Created Date",
      selector: (row) => <span>{moment(row.createdAt).format("DD-MM-YYYY HH:mm")}</span>,
    },
    {
      name: "Status",
      selector: (row) => (
        <div className="flex items-center gap-2">
          <span
            className={`${row.status === "active" ? "badge-success" : "badge-danger"} capitalize`}
          >
            {row.status}
          </span>
        </div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex gap-3">
          <Edit
            className="cursor-pointer"
            onClick={() => handleEditBlog(row)}
          />
          <Trash
            className="cursor-pointer"
            onClick={() => {
              setSelectedBlog(row);
              setDeleteModalVisible(true);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Card extra={"mt-4 !z-5 overflow-hidden"}>
        <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">All Blogs</h4>
          <Link to="/admin/add-blog">
            <Button>Add Blog</Button>
          </Link>
        </div>

        <div className="px-3">
          <Formik
            initialValues={{ title: "", status: "", date: new Date() }}
            onSubmit={handleSearch}
          >
            {({
              values,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="grid grid-cols-4 gap-3 pt-5">
                  <div>
                    <Field
                      label="Search By Blog Name"
                      name="title"
                      type="text"
                      placeholder="Enter blog name"
                      component={InputField}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="status"
                      className="mb-2 block text-sm font-bold text-gray-900 dark:text-white"
                    >
                      Status
                    </label>
                    <Field
                      name="status"
                      selectorOptions={statusOptions}
                      selectorLabelKey="label"
                      selectorValueKey="value"
                      placeholder="status"
                      component={SelectField}
                    />
                  </div>
                  <div>
                    <label
                      className="form-label ml-3 block text-sm font-bold text-navy-700 dark:text-white"
                      htmlFor="basic-default-fullname"
                    >
                      Search By Date
                    </label>
                    <input
                      type="text"
                      readOnly
                      onClick={toggleCalendar}
                      className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border border-gray-200 bg-white/0 p-3 text-sm outline-none dark:!border-white/10 dark:text-white"
                    />
                    {isCalendarVisible && (
                      <Card extra="flex w-[300px] z-20 flex-col px-3 py-3 !absolute">
                        <Calendar
                          onChange={(e) => {
                            toggleCalendar();
                            setFieldValue("date", e);
                          }}
                          value={values?.date}
                          prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
                          nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
                          view={"month"}
                        />
                      </Card>
                    )}
                  </div>
                  <div className="flex items-end">
                    <Button type="submit">Search</Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <DataTable
          loadingMessage="Loading..."
          isLoading={loading}
          columns={columns}
          data={blogs}
          noDataMessage={<p className="my-5 py-5">No Data found!</p>}
        />

        {isEditModalVisible && (
          <EditModal
            blog={selectedBlog}
            onClose={() => setEditModalVisible(false)}
            onUpdate={fetchBlogs}
          />
        )}

        {isDeleteModalVisible && selectedBlog && (
          <DeleteBlog
            id={selectedBlog._id}
            onDelete={handleDeleteBlog}
            message="Are you sure you want to delete this blog?"
            onClose={() => setDeleteModalVisible(false)}
          />
        )}
      </Card>
    </>
  );
};

export default AllBlogs;
