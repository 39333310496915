import React, { useState, useEffect } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { Edit, Trash } from "react-feather";
import moment from "moment";
import { Link } from "react-router-dom";
import Card from "components/card";
import DataTable from "components/dataTable/reactDataTable";
import axios from 'axios';
import { Button } from "components/ui-elements/Button";
import { get } from "services/api_Helpers";

const AllAffirmations = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchSessions = async () => {
    setLoading(true);
    try {
      const response = await get('/all-sessions');
      setSessions(response.data.sessions);
    } catch (error) {
      console.error("Error fetching sessions:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to determine payment status color
  const getPaymentStatusColor = (status) => {
    return status === 'paid' ? 'text-green-500' : 'text-red-500';
  };

  const columns = [
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Coupon Code",
      selector: (row) => row.coupon ? row.coupon.couponCode : "Coupon Not Applied",
      cell: (row) => (
        <span style={{ color: row.coupon ? "inherit" : "#6c757d" }}>
          {row.coupon ? row.coupon.couponCode : "Coupon Not Applied"}
        </span>
      ),
    },
    
    {
      name: "Price",
      selector: (row) => row.price ? row.price.price : "N/A",
    },
    {
      name: "Payment Status",
      selector: (row) => (
        <span className={`font-bold ${getPaymentStatusColor(row.paymentStatus)}`}>
          {row.paymentStatus.charAt(0).toUpperCase() + row.paymentStatus.slice(1)}
        </span>
      ),
    },
    {
      name: "Created At",
      selector: (row) => moment(row.createdAt).format("LLLL"),
    },
  ];

  return (
    <>
      <Card extra={"mt-4 !z-5 overflow-hidden"}>
        <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">All Sessions</h4>
        </div>

        <DataTable
          loadingMessage="Loading..."
          isLoading={loading}
          columns={columns}
          data={sessions}
          noDataMessage={<p className="my-5 py-5">No Data found!</p>}
        />
      </Card>
    </>
  );
};

export default AllAffirmations;
